#slider {
  position: relative;
  position: relative;
  width: calc(100% - 26%);
  height: 18vw;
  font-family: "Helvetica Neue", sans-serif;
  perspective: 1400px;
  transform-style: preserve-3d;
  left: 13%;
  max-height: 450px;
}

input[type="radio"] {
  position: relative;
  top: 108%;
  /* left: 50%; */
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  opacity: 0.4;
  /* transform: translateX(-83px); */
  cursor: pointer;
}

input[type="radio"]:nth-child(5) {
  margin-right: 0px;
}

input[type="radio"]:checked {
  opacity: 1;
}

#slider label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: white;
  font-size: 70px;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: transform 400ms ease;
  overflow: hidden;
}
/* Slider Functionality */

/* Active Slide */
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.3), 0 12px 6px rgba(0, 0, 0, 0.2);
  transform: translate3d(0%, 0, 0px);
}

/* Next Slide */
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: translate3d(15%, 0, -100px);
}

/* Next to Next Slide */
#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  transform: translate3d(30%, 0, -250px);
}

/* Previous to Previous Slide */
#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  transform: translate3d(-30%, 0, -250px);
}

/* Previous Slide */
#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: translate3d(-15%, 0, -100px);
}

.ak-groups-top {
  display: inline-block;
  list-style: outside none none;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}
.ak-groups-top > li {
  border-bottom: 1px dashed #c2c2ce;
  /*margin-bottom: 10px;
    padding-bottom: 5px;*/
  display: flex;
  padding: 5px;
}
.ak-groups-top i {
  font-size: 20px;
  line-height: 27px;
}
.ak-groups-top .your-grp > a.join-btn {
  color: #92929e;
  display: inline-block;
  font-size: 12px;
  width: auto;
}
.ak-groups-top .your-grp h5 {
  line-height: 28px;
}
.join-btn {
  background: #deebf3 none repeat scroll 0 0;
  border-radius: 5px;
  font-size: 12px;
  padding: 4px 14px;
  transition: all 0.2s ease 0s;
}
a.say-hi {
  background: #deebf3 none repeat scroll 0 0;
  border: 1px solid #088dcd;
  border-radius: 100%;
  color: #088dcd;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  transition: all 0.2s ease 0s;
}
.upper-layer.vid-play-block {
  width: 100%;
  height: 100%;
  position: absolute;
}
.menu-caro .owl-item a {
  padding: 10px 0px;
}
.ak-groups li:hover,
.company-posts li:hover,
.recent-links li:hover,
.followers li:hover {
  background-color: #f0f5ff;
}
ul.suggested-user-wgt li:hover {
  background: #f0f5ff;
}
.company-posts li:hover {
  background-color: #f0f5ff;
}
#challenge-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  height: 100vh;
  position: fixed;
}
.width-95 {
  width: 95%;
}
.aa {
  background: #deebf3 none repeat scroll 0 0;
  border-radius: 5px;
  font-size: 12px;
  /* padding: 4px 14px; */
  width: 100%;
  padding: 0;
  height: 26px;
}
.aa:focus {
  box-shadow: none;
}
.aa:hover {
  background: #088dcd !important;
  color: #fff;
}
.aa_active {
  background: #088dcd !important;
  color: #fff;
}
.active-menu {
  background-color: #f0f5ff;
}
.active-menu a {
  color: #088dcd;
}
.suggested-user-wgt figure {
  width: 10% !important;
  float: left;
}
.suggested-user-wgt small {
  display: block;
}
.suggested-user-wgt .sugested-user-title {
  width: 90%;
  float: left;
}
.suggested-user-wgt .s-user-name {
  width: 50%;
  float: left;
}
.suggested-user-wgt .flw-btn {
  /* float: right; */
  float: left;
  width: 25%;
}
@media only screen and (max-width: 480px) {
  #slider {
    height: 35vw;
  }
  .app-banner {
    display: block !important;
  }
  .mob-mar-t-20 {
    margin-top: 20px;
  }
  .sidebar.static.right {
    display: none;
  }
}
